<template>
  <rj-survey-question
    :survey="survey"
    :funnel="funnel"
    @answer="redirect"
  />
</template>

<script>
import RjSurveyQuestion from '@/views/common/RjSurveyQuestion.vue';

export default {
  name: 'RjReview',

  components: { RjSurveyQuestion },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },

    threePack: {
      type: Array,
      required: true,
    },
  },

  methods: {
    redirect(answer) {
      const sentiment = answer ? 'positive' : 'negative';

      return this.$router.replace({ name: `${sentiment}-review` });
    },
  },
};
</script>
